.grey-block {
    padding-top: 40px;
    padding-bottom: 40px;

    &>.row {
        padding: 60px 80px;
        @include media-breakpoint-down(sm) {
            padding: 60px 40px;
        }
        @include media-breakpoint-down(xs) {
            padding: 60px 24px;
        }
        .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .paragraph-block {
            padding: 40px 0px 0px;
        }
        .svg-block {
            padding: 40px 0px 0px;
        }
        .icon-block {
            padding: 40px 0px 0px;
            &>.row {
                margin-bottom: -80px;
            }
        }
    }
}