.svg-block{
    margin-bottom: 120px;
    margin-top: 20px;
}

@include media-breakpoint-down(md){
    .svg-block{
        margin-bottom: 70px;
        margin-top: 20px;
    }
}