@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Th.eot');
    src: url('../fonts/IcelandairLoft-W-Th.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Th.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Th.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Th.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Lt.eot');
    src: url('../fonts/IcelandairLoft-W-Lt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Lt.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Lt.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Lt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Rg.eot');
    src: url('../fonts/IcelandairLoft-W-Rg.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Rg.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Rg.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Rg.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Md.eot');
    src: url('../fonts/IcelandairLoft-W-Md.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Md.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Md.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Sb.eot');
    src: url('../fonts/IcelandairLoft-W-Sb.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Sb.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Sb.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Sb.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Bd.eot');
    src: url('../fonts/IcelandairLoft-W-Bd.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Bd.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Bd.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Bd.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Bk.eot');
    src: url('../fonts/IcelandairLoft-W-Bk.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Bk.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Bk.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Bk.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Icelandair Loft';
    src: url('../fonts/IcelandairLoft-W-Hv.eot');
    src: url('../fonts/IcelandairLoft-W-Hv.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IcelandairLoft-W-Hv.woff2') format('woff2'),
        url('../fonts/IcelandairLoft-W-Hv.woff') format('woff'),
        url('../fonts/IcelandairLoft-W-Hv.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
