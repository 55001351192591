.gri-block{
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: scroll;
    .row {
        min-width: 895px;
        &.head {
            border-bottom: 2px solid rgba(94, 94, 94, 0.2);
        }
        &.body {
            border-bottom: 1px solid rgba(94, 94, 94, 0.2);
            &:nth-last-child(1) {
                border: none;
            }
            &>div {
                &:nth-child(2n) {
                    background-color: rgba(94, 94, 94, 0.1);
                }
            }
        }
    }
    a, a:hover, a:visited{
        color: $grey;
    }
    .gri.square{
        width: 14px;
        height: 14px;
        border: 1px solid black;
        background-color: $grey-light;
        position: relative;
        &:after{
            content: '';
            width: 0px;
            height: 0px;
            background-color: black;
            position: absolute;
            left: 1px;
            top: 1px;
        }
        &.fulfilled:after{
            width: 10px;
            height: 10px;
        }
        &.part_fulfilled:after{
            content: '';
            width: 5px;
            height: 10px;
        }
        &.not_applicable:after{
            content: '';
            width: 1px;
            height: 17px;
            top: 0;
            left: 0;
            background-color: black;
            position: absolute;
            transform: rotate(-45deg);
            transform-origin: top left;
        }
    }
    .gri-heading{
        padding-top: 15px;
        padding-bottom: 5px;
        &+.gri-heading{
            border-top: none;
        }
    }
}