.image-list-block {
    padding-top: 40px;
    padding-bottom: 40px;
    .image {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-down(md){
            margin-top: 26px;
        }
        .image-wrapper{
            height: 65px;
            display: flex;
            align-items: center;
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 220px;
            max-height: 60px;
            height: auto;
            filter: invert(1);
            @include media-breakpoint-between(md, lg){
                max-width: 100%;
            }
            .color-blue &{
                filter: unset;
            }
        }
        .text {
            margin: 18px auto 0px;
            display: flex;
            max-width: 265px;
            text-align: center;
            svg {
                width: 12px;
                min-width: 12px;
                height: 20px;
                margin-right: 6px;
                margin-top: 5px;
                path {
                    .color-blue &{
                        fill: $yellow;
                    }
                    fill: $grey-light;
                }
            }
            p {
                .color-blue &{
                    color: $white;
                }
            }
        }
    }
}