html{
    scroll-behavior: smooth;
    font-size: 62.5%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
    color: $font-color;
    background-color: $white;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Icelandair Loft' !important;
    font-size: 1.8rem;
    line-height: 2.3rem;
    letter-spacing: 1.5px;
    padding-top: 100px;
    
    &:not(.page-homepage){
        padding-bottom: 160px;
    }

    &.page-standardindexpage{
        .standard-page{
            padding-top: 0;
        }
    }
}

a{
    text-decoration: none;
}

p{
    line-height: 2.5rem;
}

h1{
    color: $blue;
    font-size: calc(26px + (40 - 30) * ((100vw - 768px) / (1920 - 768)));
    font-weight: 500;
    line-height: calc(36px + (48 - 36) * ((100vw - 768px) / (1920 - 768)));
    letter-spacing: .25px;

    @media (min-width: 1920px){
        font-size: 40px;
        line-height: 48px;
    }

    @media (max-width: 768px){
        font-size: 30px;
        line-height: 36px;
    }
}

h2, h3{
    font-size: calc(20px + (22 - 20) * ((100vw - 300px) / (1440 - 300)))!important;
    font-weight: 700;
    color: $blue;

    @media (min-width: 1440px){
        font-size: 22px;
    }
    
    @media (max-width: 768px){
        font-size: 20px;
    }
}

.subtitle{
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1440 - 300)));
    color: $font-color;
    
    @media (min-width: 1440px){
        font-size: 20px;
    }
    
    @media (max-width: 768px){
        font-size: 18px;
    }
}

h3{
}
h4{}
h5{}
h6{}