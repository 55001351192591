.attention-item {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    
    .title {
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        .color-blue &{
            color: $white;
        }
    }
    .main-title {
        color: $blue;
        font-weight: 600;
        font-size: 76px;
        line-height: 70px;
        .color-blue &{
            color: $yellow;
        }
    }
    .sub-text {
        font-size: 13px;
        line-height: 16px;
        opacity: 0.7;
        .color-blue &{
            color: $white;
        }
    }
}

.attention-block {
    &>.row {
        @include media-breakpoint-down (md) {
            justify-content: center!important;
        }
        @include media-breakpoint-down (sm) {
            padding: 0px 24px;
        }
        .attention-item {
            max-width: 50%;
            min-width: 180px;
            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
            .main-title {
                font-size: 32px;
                line-height: 30px;
            }
        }
    }
}

.two-col-block {
    .attention-block {
        &>.row {
            .attention-item {
                min-width: 180px;
                max-width: 180px;
                .title {
                    font-size: 20px;
                }
                .main-title {
                    font-size: 55px;
                    line-height: 50px;
                }
            }
        }
    } 
}

@include media-breakpoint-down(md){
    .attention-item {
        .main-title {
            font-size: 48px;
        }
    }
}