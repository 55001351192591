.leidakerfi-block {
    padding: 80px 0px;
    height: 636px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
        height: 460px;
    }
    @include media-breakpoint-down(xs) {
        height: unset;
    }
    .mask-container {
        height: 476px;
        width: 1140px;
        position: relative;
        @include media-breakpoint-down(lg) {
            width: 960px;
        }
        @include media-breakpoint-down(md) {
            width: 720px;
            height: 300px;
        }
        @include media-breakpoint-down(sm) {
            width: 540px;
            height: 220px;
        }
        @include media-breakpoint-down(xs) {
            width: 100%;
            height: auto;
            position: unset;
        }
        .mask {
            transition: 1s all ease-in-out;
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 0%;
            top: 0px;
            left: 0px;
            overflow: hidden;
            @include media-breakpoint-down(xs) {
                position: unset;
                padding: 0px 24px;
            }
            &.closed {
                transition: 1s all ease-in-out;
                height: 1%;
                width: 1%;
                border-radius: 50%;
                left: 803px;
                top: 115px;
                // border: 1px red solid;
                @include media-breakpoint-down(lg) {
                    left: 675px;
                    top: 135px;
                }
                @include media-breakpoint-down(md) {
                    left: 507px;
                    top: 73px;
                }
                @include media-breakpoint-down(sm) {
                    height: 3%;
                    width: 3%;
                    left: 375px;
                    top: 50px;
                }
                @include media-breakpoint-down(xs) {
                    width: 100%;
                }
                svg {
                    transition: 1s all ease-in-out;
                    left: -803px;
                    top: -115px;
                    @include media-breakpoint-down(lg) {
                        left: -675px;
                        top: -135px;
                    }
                    @include media-breakpoint-down(md) {
                        left: -507px;
                        top: -73px;
                    }
                    @include media-breakpoint-down(sm) {
                        left: -375px;
                        top: -50px;
                    }

                }
            }
            svg {
                transition: 1s all ease-in-out;
                position: absolute;
                width: 1140px;
                top: 0px;
                left: 0px;
                @include media-breakpoint-down(lg) {
                    width: 960px;
                }
                @include media-breakpoint-down(md) {
                    width: 720px;
                    height: 300px;
                }
                @include media-breakpoint-down(sm) {
                    width: 540px;
                    height: 220px;
                }
                @include media-breakpoint-down(xs) {
                    position: unset;
                    width: 100%;
                }
            }
        }
    }
}