.multi-address-block{
    h1.title{
        font-size: calc(20px + (28 - 20) * ((100vw - 300px) / (1440 - 300)));
        font-weight: 600;
        margin-bottom: 30px;
        color: $blue;

        @media (min-width: 1440px){
            font-size: 28px;
        }

        @media (max-width: 768px){
            font-size: 20px;
        }
    }
    .chairman{
        margin-bottom: 60px;
    }
    .read-more-container{
        z-index: 100;
    }
}
