.icon-stats-block {
    padding-top: 10px;
    
    .text-col {
        margin-bottom: 70px;

        @include media-breakpoint-down(md) {
            text-align: center;
            flex-direction: column;
        }

        .img-container{
            margin-right: 30px;

            @include media-breakpoint-down(md) {
                margin-right: 0; 
                margin-bottom: 20px;
            }

            img{
                max-width: 60px;
                min-width: 60px;
            }    
        }

        .text {
            .number {
                font-size: calc(22px + (32 - 22) * ((100vw - 768px) / (1920 - 768)));
                font-weight: 700;
                color: $blue;
                margin-bottom: 10px;

                @media (min-width: 1920px){
                    font-size: 32px;
                }

                @media (max-width: 768px){
                    font-size: 22px;
                }

                @include media-breakpoint-down(lg) {
                    margin-bottom: 4px;
                }
            }

            .title {
                font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
                font-weight: 600;
                color: $blue;
                margin-bottom: 8px;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }

                @media (min-width: 1920px){
                    font-size: 24px;
                }

                @media (max-width: 768px){
                    font-size: 20px;
                }
            }

            .last-year {
                color: $grey;
                font-size: 18px;
                font-weight: 400;

                @media (max-width: 768px){
                    font-size: 13px;
                    line-height: 16px;                
                }
            }
        }
    }
}