.paragraph-block{
    h2{
        color: $blue;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: 48px;
    }

    p{
        font-size: calc(18px + (20 - 18) * ((100vw - 768px) / (1920 - 768)));
        letter-spacing: 0.25px;
        line-height: calc(26px + (34 - 26) * ((100vw - 768px) / (1920 - 768)));

        @media (min-width: 1920px){
            font-size: 20px;
            line-height: 34px;
        }

        @media (max-width: 768px){
            font-size: 18px;
            line-height: 26px;
        }
    }
}

.container.paragraph-block{
    max-width: 1200px;
    flex: 0 0 auto;
    width: 87.5%;
    @media (min-width: 768px)
    {
        width: 75%;
    }
    @media (min-width: 992px)
    {
        width: 62.5%;
    }
}
