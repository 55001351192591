


$white: #ffffff;
$white-d1: #f4f4f4;
$white-d2: #D8D8D8;

$font-color: #435064;

$grey-d2: #7B8593;
$grey-d1: #444444;
$grey: #5e5e5e;
$grey-light: #d6d6d6;
$grey-l1: #ececf0;
$grey-l2: #F2F4F8;

$blue-l2: #0C68FF;
$blue-l1: #55C0E8;
$blue: #001b71;
$blue-75: #001b71bf;
$pink: #ff47b3;
$yellow: #ffda00;
