.table-block-new {
    margin-bottom: 50px;

    .caption{
        color: $blue;
        font-size: calc(26px + (40 - 26) * ((100vw - 768px) / (1920 - 768)));
        font-weight: 500;
        line-height: calc(36px + (48 - 36) * ((100vw - 768px) / (1920 - 768)));
        letter-spacing: .25px;
        margin-bottom: 40px;


        @media (min-width: 1920px){
            font-size: 40px;
            line-height: 48px;
        }

        @media (max-width: 768px){
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 10px;
        }
    }

    @include media-breakpoint-down(md) {
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    .row {
        @include media-breakpoint-down(md) {
            min-width: 895px;
        }
    }

    .table th,
    .table td {
        border-top: none;
    }

    .table thead tr th {
        color: $blue;
    }

    .table tbody th {
        color: $blue;
        font-size: calc(14px + (18 - 14) * ((100vw - 768px) / (1920 - 768)));
        letter-spacing: 0.25px;
        line-height: 20px;

        @media (min-width: 1920px){
            font-size: 18px;
        }

        @media (max-width: 768px){
            font-size: 14px;
        }
    }

    .table tbody td {
        font-size: calc(15px + (18 - 15) * ((100vw - 768px) / (1920 - 768)));
        letter-spacing: 0.25px;
        line-height: 20px;

        @media (min-width: 1920px){
            font-size: 18px;
        }

        @media (max-width: 768px){
            font-size: 15px;
        }
    }

    .table tbody tr td {
        padding-bottom: 12px;
        padding-top: 12px;
    }
}