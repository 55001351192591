.icon-block {
    margin-bottom: 90px;

    .icon {
        margin-bottom: 88px;
        max-width: 240px;
        text-align: center;
        
        img{
            margin-bottom: 20px;
            min-width: 78px;
        }

        p {
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 20px;
        }
    }
}