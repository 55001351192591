.portrait-image-list-block {
    padding-bottom: 120px;

    .title {
        font-size: calc(26px + 14 * (100vw - 768px) / 1152);
        line-height: calc(36px + 12 * (100vw - 768px) / 1152);
        font-weight: 500;
        margin-bottom: 30px;
        color: $blue;
        letter-spacing: 0.25px;
    }

    .outer-container {
        margin-right: 3%;
        margin-bottom: 42px;

        @media (min-width: 1400px) {
            width: 30%;
        } 
        @media (min-width: 1200px) and (max-width: 1399px) {
            width: 47%;
        }
    }

    .inner-container {
        border-radius: 5px;
        margin-bottom: 42px;
        overflow: hidden;
        height: 100%;
        filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.15));

        .img-container {
            img {
                width: 100%;
                height: auto;
            }
        }

        .text-container {
            background-color: $white;
            min-height: 126px;
            padding: 10px;
            height: 100%;

            .name {
                font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
                line-height: calc(22px + (38 - 22) * ((100vw - 300px) / (1920 - 300)));
                padding-top: 12px;
                font-weight: 500;
                color: $blue;

                @media (min-width: 1920px) {
                    font-size: 26px;
                    line-height: 38px;
                }

                @media (max-width: 768px) {
                    font-weight: 18px;
                    line-height: 22px;
                }
            }

            .position {
                font-size: 16px;
                line-height: 28px;
            }

            .bio{
                padding: 0 20px 0 20px;

                p{
                    font-size: 14px;
                    line-height: 21px;
                    margin-top: 20px;
                    letter-spacing: .3px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .portrait-image-list-block {
        .outer-container{
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }

        .text-container {
            padding: 0 8px;

            .position {
                font-size: 12px;
                line-height: 28px;
            }
        }
    }
}