.chairman-address-block {
    padding-top: 40px;
    padding-bottom: 0;
    position: relative;
    &.body-open {
        padding-bottom: 40px;
        transition: 0s padding-bottom .5s ease-in-out;
        .chairman-address-body{
            max-height: 10000px;
        }
    }
    .chairman {
        display: flex;
        align-items: flex-end;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
        }
        .title {
            margin-left: 30px;
            @include media-breakpoint-down(sm) {
                margin-left: 0px;
            }
            p {
                margin-bottom: 0px;
                font-size: 20px;
                line-height: 24px;
                &.name {
                    font-weight: 600;
                    line-height: 30px;
                }
            }
        }
    }
    .title {
        h3{
            margin-top: 32px;
            font-family: 'Neue Haas Unica Pro';
            font-size: 2rem;
            line-height: 2.8rem;
            font-weight: bold;
        }
    }
    .paragraph {
        column-count: 2;
        @include media-breakpoint-down(md) {
            column-count: 1;
        }
    }
    .read-more-container {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -60px;
        transition: .3s all ease-in-out;
        &.hide {
            transform: translateY(-20px);
            opacity: 0;
        }
        svg {
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }
        p {
            font-size: 20px;
            margin-bottom: 0px;
            cursor: pointer;
            font-weight: bold;
        }
    }
    .chairman-address-body {
        max-height: 0px;
        overflow: hidden;
        transition: .4s max-height .3s ease-in-out;
        .content {
            padding-top: 40px;
            .paragraph-block {
                padding: 0px 0px 80px;
                &:nth-last-child(1) {
                    padding-bottom: 0px;
                }
            }
        }
    }
}