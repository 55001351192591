.custom-text-block{
    .line{
        position: relative;
        border-bottom: 1px solid $grey-light;
        min-height: 60px;
        padding-bottom: 40px;
        padding-top: 40px;

        b{
            font-weight: 700;
            position: absolute;
        }
        span{
            float: left;
            padding-left: 140px;
            p{
                margin-bottom: 0;
            }
        }
    }
}