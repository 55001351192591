.page-standardpage{
    header{
        // height: 100px;
        // .menu-toggler-container{
        //     &.open{
        //         @include media-breakpoint-up(lg){
        //             span{
        //                 background-color: $white !important;
        //             }
        //         }
        //     }

        //     .menu-toggler{
        //         span{
        //             @include media-breakpoint-up(md){
        //                 &.blue{
        //                     background-color: $blue;
        //                 }
        //             }
        //         }
        //     }
        // }
        .logo{
            z-index: 100;
            @include media-breakpoint-up(md){
                svg{
                    &#icelandair-logo-white{
                        opacity: 0 !important;
                        transition: opacity 0.3s linear;
                    }
                    &#icelandair-logo-blue{
                        opacity: 1 !important;
                        transition: opacity 0.3s linear;
                    }
                    &#icelandair-logo{
                        path {
                            fill: $blue;
                            transition: fill 0.3s linear;
                        }
                    }
                }
            }
            @include media-breakpoint-down(md){
                svg{
                    &#icelandair-logo-white{
                        opacity: 1 !important;
                        transition: opacity 0.3s linear;
                    }
                    &#icelandair-logo-blue{
                        opacity: 0 !important;
                        transition: opacity 0.3s linear;
                    }
                }
            }
            &.open{
                svg{
                    &#icelandair-logo-white{
                        opacity: 1 !important;
                        transition: opacity 0.3s linear;
                    }
                    &#icelandair-logo-blue{
                        opacity: 0 !important;
                        transition: opacity 0.3s linear;
                    }
                    &#icelandair-logo{
                        path {
                            fill: $white;
                            transition: fill 0.3s linear;
                        }
                    }
                }
            }
        }
    }
}
.standard-page{
    padding-top: 146px;

    @include media-breakpoint-down(md) {
        padding-top: 70px;
    }
    
    .page-icon{
        display: none;
    }
}
.standard-page-title-23 {
    background-color: $white;
    border-radius: 8px;
    padding: 24px 24px;
    position: absolute;
    bottom: 165px;
    max-width: 630px;
    left: 118px;
    z-index: 2;

    .inner-container{
        position: relative;
    
        &::before{
          background-color: $pink;
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
          left: -24px;
          width: 8px;
        }
        
        h1{
          color: $blue;
          font-size: 40px;
          font-weight: 500;
          line-height: 120%;
        }
        
        span{
          color: $blue;
          font-size: 18px;
          font-weight: 400;
        }
      }
}
.standard-page-subtitle{
    position: absolute;
    top: 60%;
    left: 4.9%;
    font-size: 30px;
    color: $white;
    max-width: 414px;
    z-index: 1;
    @include media-breakpoint-down(md) {
        left: 24px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 30px;
        top: 55%;
    }
}


@include media-breakpoint-down(sm){
    .page-standardpage, .page-standardindexpage{
        .standard-page{
            header{
                .menu-toggler{
                    top: 40px !important;
                }
            }
            
            .standard-page-title{
                font-size: 20px !important;
                max-height: 54px;
                max-width: 90%;
                top: 100px;
                z-index: 0;
            }
            
            .page-title-mobile{
                padding: 0 24px;
                
                .mobile{
                    color: $blue;
                    font-size: 36px !important;
                    font-weight: 600;
                    left: unset;
                    position: relative;
                    top: unset;
                    
                    &::after{
                        content: none;
                    }
                    
                    span{
                        background-color: transparent;
                        margin-bottom: 0;
                        padding: 0;
                        
                        &::after{
                            content: none;
                        }
                    }
                }
            }
        }

        footer{
            .menuitems{
                .menuitem{
                    a{
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}