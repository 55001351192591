.statistics-container {
  padding-top: 40px;
  padding-bottom: 40px;
  .statistic {
    flex-direction: column;
    @include media-breakpoint-down(md) {
      padding: 0px 24px 48px;
    }
    .percentage {
      width: 164px;
      height: 164px;
      min-width: 164px;
      position: relative;
      align-self: center;
      margin-bottom: 32px;
      @include media-breakpoint-down(md) {
        align-self: flex-start;
        margin-bottom: 0px;
      }
      .cover-container {
        clip: rect(0, 82px, 164px, 0);
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        .cover {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 50%;
          background-color: $blue;
          border: 5px solid $blue;
          z-index: 1;
          clip: rect(0, 82px, 164px, 0);
          transform: rotate(0deg);

          transition: 1s transform .2s ease-in-out;
          -moz-transition: 1s transform .2s ease-in-out;
          -o-transition: 1s transform .2s ease-in-out;
          -webkit-transition: 1s transform .2s ease-in-out;
          &>div {
            position: absolute;
            width: 152.5px;
            height: 152.5px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 3.5px solid $yellow;
          }
        }
      }
      .c100 {
        position: relative;
        font-size: 164px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        &:before {
          content: "";
          position: absolute;
          width: 93%;
          height: 93%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 4px solid #ffb600;
        }
        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          content: " ";
          border-radius: 50%;
          background-color: $blue;
          width: 80%;
          height: 80%;
        }
        &> span {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          color: #ffb600;
          display: block;
          text-align: center;
          white-space: nowrap;
          font-size: 32px;
          font-weight: bold;
        }
        .slice {
            position: absolute;
            width: 100%;
            height: 100%;
            clip: rect(0em, 100%, 100%, 50%);
        }
      }
      
      .c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
        clip: rect(auto, auto, auto, auto);
      }
        
      .c100 .bar, .c100 .fill {
        position: absolute;
        border: 20px solid #ffb600;
        width: 122px;
        height: 122px;
        left: calc(50% - 81px);
        top: calc(50% - 81px);
        clip: rect(0, 81px, 164px, 0em);
        border-radius: 50%;
        transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
      
        transition: 1s transform ease-in-out;
        -moz-transition: 1s transform ease-in-out;
        -o-transition: 1s transform ease-in-out;
        -webkit-transition: 1s transform ease-in-out;
      }
      
      .c100.p51 .bar, .c100.p52 .bar, .c100.p53 .bar, .c100.p54 .bar, .c100.p55 .bar, .c100.p56 .bar, .c100.p57 .bar, .c100.p58 .bar, .c100.p59 .bar, .c100.p60 .bar, .c100.p61 .bar, .c100.p62 .bar, .c100.p63 .bar, .c100.p64 .bar, .c100.p65 .bar, .c100.p66 .bar, .c100.p67 .bar, .c100.p68 .bar, .c100.p69 .bar, .c100.p70 .bar, .c100.p71 .bar, .c100.p72 .bar, .c100.p73 .bar, .c100.p74 .bar, .c100.p75 .bar, .c100.p76 .bar, .c100.p77 .bar, .c100.p78 .bar, .c100.p79 .bar, .c100.p80 .bar, .c100.p81 .bar, .c100.p82 .bar, .c100.p83 .bar, .c100.p84 .bar, .c100.p85 .bar, .c100.p86 .bar, .c100.p87 .bar, .c100.p88 .bar, .c100.p89 .bar, .c100.p90 .bar, .c100.p91 .bar, .c100.p92 .bar, .c100.p93 .bar, .c100.p94 .bar, .c100.p95 .bar, .c100.p96 .bar, .c100.p97 .bar, .c100.p98 .bar, .c100.p99 .bar, .c100.p100 .bar {
        &:after {
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        } 
      }

      .c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .fill {
        transition: 1s transform ease-in-out;
        -moz-transition: 1s transform ease-in-out;
        -o-transition: 1s transform ease-in-out;
        -webkit-transition: 1s transform ease-in-out;
    }
        
      .c100 *, .c100 *:before, .c100 *:after {
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
      }
    }
    
    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        margin-left: 0px;
        margin-top: 20px;
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        max-width: 232px;
      }
      .title {
        font-weight: bold;
      }
      .subtitle, .title {
        font-size: 20px;
        line-height: 28px;
        color: $white;
        margin-bottom: 0px;
      }
      .text {
        font-size: 18px;
        color: $white;
    
      }
    }
  }
}
