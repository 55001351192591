.three-col-info{
    margin-bottom: 55px;

    .title{
        color: $blue;
        font-size: calc(26px + (40 - 26) * ((100vw - 768px) / (1920 - 768)));
        font-weight: 500;
        line-height: calc(36px + (48 - 36) * ((100vw - 768px) / (1920 - 768)));
        letter-spacing: .25px;
        margin-bottom: 20px;


        @media (min-width: 1920px){
            font-size: 40px;
            line-height: 48px;
        }

        @media (max-width: 768px){
            font-size: 26px;
            line-height: 36px;
        }
    }

    .outer-col{
        background-color: $grey-l2;

        .inner-col{
            padding: 30px 35px 39px 35px;

            .col{
                min-width: 200px;

                &:last-child{
                    .border-col{
                        border-right: none !important;
                    }
                }

                &:first-child{
                    .col-title{
                        padding-left: 0 !important;
                    }

                    .border-col{
                        padding-left: 0 !important;
                    }
                }
            }
        }

        .col-title{
            font-weight: 600;
            padding-left: 20px;
            margin-bottom: 12.5px;
        }

        .border-col{
            border-top: 1px solid #D1D6DB;
            border-right: 1px solid #D1D6DB;
            height: calc(100% - 25px);
            padding: 18px 20px 25px 20px;

            ul{
                padding-left: 0;

                li{
                    font-size: 18px;
                    letter-spacing: 0.25px;
                    line-height: 22px;
                    list-style: none;
                    margin-bottom: 17px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .three-col-info{
        .outer-col{
            .inner-col{
                .col{
                    min-width: 100%;
                }
            }

            .col-title{
                border-bottom: 1px solid #D1D6DB;
                padding-left: 0 !important;
                padding-bottom: 8px;
            }

            .border-col{
                border: none !important;
                padding-left: 0 !important;
            }
        }
    }
}