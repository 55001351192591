.image-block {
    margin-top: 60px;
    margin-bottom: 90px;

    .row {
        overflow: hidden;

        .image-container {
            height: 450px;
            position: relative;
            width: 100%;

            &.full_height {
                height: unset;
            }

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .image-block {
        margin-top: 30px;
        margin-bottom: 60px;

        .row {
            .image-container {
                height: 250px;
            }
        }
    }
}