.embed-block {
    margin-bottom: 49px;
    
    .responsive-object {
        position: relative;
        padding-bottom: 67.5%;
        height: 0;
        margin: 10px 0;
        overflow: hidden;
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}