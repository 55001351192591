.calendar-block {
    padding-top: 40px;
    padding-bottom: 40px;

    .events-container {
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
        .events {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            min-width: 1045px;
            .content {
                width: 185px;
                height: 185px;
                .event {
                    padding: 24px;
                    height: calc(100% - 48px);
                    background-color: rgba($grey, .05);
                    p {
                        line-height: 28px;
                    }
                }
                .date {
                    background-color: $blue;
                    height: 48px;
                    color: $white;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .calendar-block{ 
        .events-container{
            .events{
                flex-wrap: wrap;
                min-width: unset;

                .content{
                    margin-bottom: 20px;
                }
            }
        }
    }
}