body.menu-open {
    left: 0;
    position: fixed;
    top: 0;
}

header {
    box-shadow: 0px 0px 30px rgba(161, 167, 177, 0.1);
    position: fixed;
    height: 100px;
    z-index: 50;
    background-color: $white;
    transition: .3s all ease-in-out;
    top: 0;
    width: 100%;

    &.nav-up, &.nav-down {
        position: fixed;
    }

    &.nav-up {
        top: -100px;
    }

    &.nav-down {
        top: 0;
    }

    @include media-breakpoint-down(md) {
        width: 100vw;
    }

    .burger{
        cursor: pointer;

        span{
            background-color: $blue;
            border-radius: 4px;
            height: 2px;
            display: block;
            margin-bottom: 6px;
            width: 36px;

            &:first-child{
                transform: rotate(0) translate(0, 0);
                transition: transform .2s linear;
            }

            &.middle{
                transition: width .2s linear;
                width: 36px;
            }

            &:last-child{
                transform: rotate(0) translate(0, 0);
                transition: transform .2s linear;
            }
        }
    }

    .burger.open{
        span{
            &:first-child{
                transform: rotate(45deg) translate(3px, 9px);
                transition: transform .2s linear;

                @media (max-width: 768px){
                    transform: rotate(45deg) translate(1px, 8px);
                }
            }

            &.middle{
                transition: width .2s linear;
                width: 0;
            }

            &:last-child{
                transform: rotate(-45deg) translate(2px, -8px);
                transition: transform .2s linear;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .logo-outer-container {
            margin-right: 42px;
            padding-left: 103px;

            a {
                svg {
                    width: 256px;
                }
            }
        }

        .nav-items {
            height: 100%;

            ul.menuitems {
                height: 100%;
                margin-bottom: 0;

                li.menuitem {
                    align-items: center;
                    display: flex;
                    height: 100%;
                    list-style: none;
                    margin-right: 32px;
                    position: relative;
                    
                    a {
                        text-decoration: none;
                        
                        span{
                            font-size: 18px;
                            font-weight: 500;
                            letter-spacing: 1.5px;
                            color: #4D5F9C;
                        }
                    }

                    &.active{
                        position: relative;

                        &::after{
                            background-color: $blue;
                            bottom: 0;
                            content: '';
                            height: 4px;
                            left: 0;
                            position: absolute;
                            width: 100%;
                        }

                        a{
                            span{
                                color: $blue;
                                font-weight: 600;
                            }
                        }
                    }

                    &.submenu-active{
                        .child-nav{
                            max-height: unset;
                            padding: 24px 24px 8px 40px;
                        }
                    }


                    .child-nav {
                        background-color: $white;
                        box-shadow: 0px 4px 30px 0px rgba(0, 27, 113, 0.20);
                        border-radius: 0px 0px 8px 8px;
                        left: -1px;
                        min-width: max-content;
                        top: 100%;
                        max-height: 0;
                        position: absolute;
                        overflow: hidden;

                        li{
                            position: relative;
                            margin-bottom: 16px;
                            margin-right: 44px;
                            list-style: none;

                            &:hover{
                                &::before{
                                    transition: width .2s linear;
                                    width: 10px;
                                }
                            }

                            &.active-site{
                                &::before{
                                    background-color: $pink;
                                }

                                a{
                                    span{
                                        font-weight: 700;
                                    }
                                }
                            }
                            
                            &::before{
                                background-color: $blue;
                                content: '';
                                height: 18px;
                                left: -16px;
                                top: 2px;
                                transition: width .2s linear;
                                position: absolute;
                                width: 8px;
                            }
                            
                            a{
                                span{
                                    color: $blue;
                                    font-size: 18px;
                                    font-weight: 400;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 1300px){
    header{
        .logo-outer-container{
            padding-left: 24px !important;

            .logo{
                svg{
                    width: 200px;
                }
            }
        }

        .nav-items{
            .menuitems{
                .menuitem{
                    margin-right: 24px !important;

                    a{
                        span{
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    header{
        .burger{
            margin: 0 16px 0 auto;
        }

        .logo-outer-container{            
            padding-left: 8px !important;

            .logo{            
                svg{
                    width: 200px;
                }
            }
        }
        
        .nav-items{
            background-color: $white;
            height: 0;
            left: 0;
            position: fixed;
            top: 124px;
            overflow: hidden;
            width: 100vw;
            
            &.open{
                height: calc(100vh - 100px);
                padding: 32px 15px 15px 30px;
            }
            
            ul.menuitems{
                align-items: unset !important;
                flex-direction: column;
                min-width: 60vw;
                overflow: scroll;
                width: 100%;
                
                li.menuitem{
                    list-style: none;
                    margin-bottom: 32px;
                    position: relative;

                    &::before{
                        background: $blue;
                        content: '';
                        height: 22px;
                        left: -16px;
                        position: absolute;
                        top: 0;
                        width: 8px;
                    }

                    &::after{
                        content: '';
                        right: 20px;
                        top: 4px;
                        position: absolute;
                        width: 0px;
                        height: 0px;
                        border: solid #4D5F9C;
                        border-width: 0 1px 1px 0;
                        display: inline-block;
                        padding: 5px;
                        transform: rotate(45deg);         
                        transition: transform .2s linear;
                    }

                    &:first-child{
                        &::after{
                            content: none;
                        }   
                    }

                    &.active{
                        &::before{
                            background-color: $pink;
                        }

                        &::after{
                            transform: rotate(-135deg);
                            transition: transform .2s linear;
                        }

                        a{
                            span{
                                font-weight: 700;
                            }
                        }

                        ul.child-nav{
                            max-height: 600px;
                            transition: max-height .3s linear;

                            li.active-site{
                                &::before{
                                    height: 2px;
                                }

                                a{
                                    span{
                                        color: $blue;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                    
                    a.parent{
                        &.indexpage{
                            pointer-events: none;
                        }

                        span{
                            color: $blue;
                            font-size: 20px !important;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                    
                    ul.child-nav{
                        max-height: 0;
                        transition: max-height .3s linear;
                        overflow: hidden;

                        
                        li{
                            list-style: none;
                            margin-bottom: 12px;
                            position: relative;

                            &:first-child{
                                margin-top: 16px;
                            }

                            &:before{
                                background-color: #4D5F9C;
                                content: '';
                                height: 1px;
                                left: -14px;
                                position: absolute;
                                top: 10px;
                                width: 10px;
                            }

                            a{
                                span{
                                    color: #4D5F9C;
                                    font-size: 16px !important;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    header{
        .burger{
            span{
                height: 1.5px;
                margin-bottom: 5px;
                width: 24px;
            }

            span.middle{
                width: 24px;
            }
        }

        .nav-items{
            top: 100px;
        }

        .logo{
            margin-left: 13px;

            svg{
                width: 150px;
            }
        }
    }
}