.statement-block {
    position: relative;
    display: flex;
    overflow: hidden;
    padding: 40px 0px 0px;
    .text {
        position: static;
        z-index: 1;
        .statement {
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
            margin-bottom: 27px;
            color: $pink;
        }
        .title {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 27px;
            font-weight: normal!important;
        }
        .subtitle {
            font-size: 20px;
            line-height: 28px;
        }
        .pointers {
            margin-top: 80px;
            margin-bottom: 40px;
            .point {
                max-width: 16%;
                text-align: center;
                .number {
                    margin: 0 auto 20px;
                    width: 58px;
                    height: 58px;
                    border: 1px solid white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 4px;
                    span {
                        font-size: 38px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}