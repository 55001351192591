.homepage-title-23 {
  background-color: $white;
  border-radius: 8px;
  padding: 24px 24px;
  position: absolute;
  top: 318px;
  max-width: 630px;
  left: 118px;
  z-index: 2;

  .inner-container{
    position: relative;

    &::before{
      background-color: $pink;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: -24px;
      width: 8px;
    }
    
    h1{
      color: $blue;
      font-size: calc(24px + (48 - 24) * ((100vw - 768px) / (1920 - 768)));
      font-weight: 600;
      line-height: 120%;

      @media (min-width: 1920px){
        font-size: 48px;
      }

      @media (max-width: 768px){
          font-size: 24px;
      }
    }
    
    span{
      color: $blue;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.header_image_container {
  width: 100vw;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
  padding: 0 30px 30px;
  margin-bottom: 150px;

  &.focus-top {
    img {
      object-position: top center;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  svg {
    cursor: pointer;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    animation: jump 3s infinite;
    padding: 20px;

    path {
      fill: $pink;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}



@-webkit-keyframes jump {
  0% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  20% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  30% {
    -webkit-transform: translate(-50%, -20px);
    transform: translate(-50%, -20px);
  }

  40% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  50% {
    -webkit-transform: translate(-50%, -15px);
    transform: translate(-50%, -15px);
  }

  60% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  100% {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}


@include media-breakpoint-down(lg){
  .homepage{
    position: relative;
  }

  .homepage-title-23{
    left: 50%;
    top: 70px;
    transform: translateX(-50%);
    width: 330px;

    .inner-container{
      h1{
        font-weight: 700;
      }

      span{
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .header_image_container{
    padding: 0 8px;
  }
}

@include media-breakpoint-down(md){
  .homepage-title-23{
    letter-spacing: 0;
  }

  .header_image_container{
    body:not(.page-homepage) &{
      height: calc(100vh - 300px);
    }
  }
}
