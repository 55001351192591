.section-block {
    padding: 80px 0px 20px;

    .color-blue &{
        padding-top: 40px;
    }
    .text {
        .title {
            color: $blue;
            .color-blue &{
                color: $white;
            }
        }
        .rich-text {
            flex: 0 0 66.66667%;
            max-width: 66.66667%;
            margin-top: 12px;
            
            .color-blue &{
                color: $white;
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .section-block {
        padding: 30px 24px 0px 24px;

        .text {
            .rich-text {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}