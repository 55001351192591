.esg-block{
    .section-title{
        color: #435064;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.25px;
        text-align: right;
        line-height: 20px;
        left: -10px;
        transform: translateX(-100%);
        top: 8px;
    }

    .inner-container{
        height: fit-content;
        position: relative;

        &::before{
            background-color:#DEE2E6;
            content: '';
            height: 100%;
            left: -21px;
            position: absolute;
            top: 0;
            width: 1px;
        }
        
        .esg-title{
            font-size: 20px;
            letter-spacing: 0.25px;
            font-weight: 600;
            line-height: 34px;
        }
        
        .esg{
            margin-bottom: 34px;
            
            &:last-child{
                margin-bottom: 60px !important;
            }
            
            p{
                font-size: calc(18px + (20 - 18) * ((100vw - 768px) / (1920 - 768)));
                letter-spacing: 0.25px;
                line-height: calc(26px + (34 - 26) * ((100vw - 768px) / (1920 - 768)));

                @media(min-width: 1920px){
                    font-size: 20px;
                    line-height: 34px;
                }
                
                @media(max-width: 768px){
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .esg-block{
        .section-title{
            left: -15px;
            margin-bottom: 12px;
            position: relative !important;
            text-align: left;
            top: 0;
            transform: unset;
        }

        .inner-container{
            &::before{
                content: none;
            }

            .esg-title{
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 6px;            
            }
        }
    }
}