.img-quote-block {
    margin-bottom: 320px;

    .content {
        position: relative;

        .img-container {
            height: 100%;
            max-height: 780px;
            padding: 0 24.5px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;

                &.top {
                    object-position: top;
                }

                &.bottom {
                    object-position: bottom;
                }
            }
        }

        svg {
            height: 60px;
            position: absolute;
            top: 23px;
            left: 62px;
            width: 38px;
            z-index: 1;

            &.right {
                left: unset;
                right: 0;
                bottom: -72px;
                top: unset;
            }
        }

        .text-col {
            position: absolute;
            bottom: -170px;
            right: 19%;
            max-width: 690px;

            &.text-left {
                left: 19%;
                right: unset;

                .person-info {
                    bottom: 80px;
                    position: absolute;
                    right: 0;
                    transform: translateX(calc(100% + 30px));

                    p {
                        text-align: left;
                    }
                }
            }

            .text-container {
                background-color: $grey-l2;
                max-width: 690px;
                padding: 90px 60px 80px 60px !important;
                position: relative;

            }
        }

        .person-info {
            bottom: 80px;
            position: absolute;
            left: 0;
            transform: translateX(calc(-100% - 30px));

            p {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
                text-align: right;

                &.quote {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    position: relative;
                }

                &.name {
                    font-size: 20px;
                    font-weight: 500;
                    margin-top: 66px;
                    margin-bottom: 0;
                }

                &.job {
                    font-size: 18px;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .img-quote-block {
        .content {
            .text-col.text-left {
                left: 3%;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .img-quote-block {
        .content {

            .text-col.text-left,
            .text-col {
                bottom: -180px;

                .text-container {
                    padding: 60px 50px 68px 50px !important;

                    svg:not(.right) {
                        left: 50px;
                        top: 0;
                    }

                    .quote {
                        font-size: 16px;
                        line-height: 23px;
                    }

                    .person-info {
                        position: relative;
                        left: unset;
                        right: unset;
                        bottom: -28px;
                        transform: none;

                        .name {
                            font-size: 17px;
                            margin-top: 36px;
                        }

                        .job {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .img-quote-block {
        margin-bottom: 60px;

        .content {
            padding: 0;

            .text-col.text-left, .text-col {
                position: relative;
                bottom: unset !important;
                left: unset !important;
                right: unset !important;
                order: 2;

                .text-container {
                    padding: 60px 40px 60px 40px !important;

                    svg:not(.right) {
                        top: 4px;
                        left: 24px;
                    }

                    svg.right{
                        bottom: -52px;
                    }
                }
            }

            .img-container{
                padding: 0;
            }
        }
    }
}
