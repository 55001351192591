.staff-block{
    margin-bottom: 20px;

    &:nth-child(even){
        .inner-container{
            justify-content: flex-end;

            img{
                margin-left: 60px;
                margin-right: unset;
                order: 2;
            }

            .text{
                order: 1;
                text-align: right;
            }
        }
    }

    .inner-container{
        background-color: $grey-l2;
        
        img{
            height: 100%;
            margin-right: 60px;
        }

        .text{
            margin-bottom: 36px;

            p{
                font-size: calc(18px + (20 - 18) * ((100vw - 768px) / (1920 - 768)));
                letter-spacing: 0.25px;
                font-weight: 500;
                letter-spacing: 0.25px;
                line-height: 24px;
                margin-bottom: 0;
                @media(min-width: 1920px){
                    font-size: 20px;
                }

                @media(max-width: 768px){
                    font-size: 18px;
                }

                &:last-child{
                    font-size: calc(16px + (18 - 16) * ((100vw - 768px) / (1920 - 768)));
                    font-weight: 400;
                    
                    @media(min-width: 1920px){
                        font-size: 18px;
                    }

                    @media(max-width: 768px){
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .staff-block{
        padding: 0 !important;
        
        &:nth-child(even){
            .inner-container{    
                img{
                    margin-left: 24px;
                }
            }
        }

        .inner-container{
            .text{
                margin-bottom: 20px;
            }

            img{
                margin-right: 24px;
            }
        }
    }
}