.block-quote-block {
    padding: 120px 0px;

    .quote {
        margin: 0 auto;
        display: flex;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        justify-content: center;

        @include media-breakpoint-down(md){
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }

        h3 {
            color: $blue;
            font-size: 28px;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: 34px;
            margin: 0px 20px;
            align-self: center;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 20px;
                line-height: 28px;
                margin: 0px 16px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 24px;
                margin: 0px 8px;
            }
        }

        svg {
            min-width: 46px;
            height: 60px;
            margin-top: -10px;

            @include media-breakpoint-down(md) {
                min-width: 54px;
                height: 54px;
            }
        }
    }
}

@include media-breakpoint-down(md){
    .block-quote-block{
        padding: 60px 0;

        .quote{
            position: relative;

            svg{
                left: 0;

                &:last-child{
                    bottom: -48px;
                    left: unset;
                    top: unset;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .block-quote-block{
        .quote{
            flex: 0 0 100%;
            max-width: 100%;

            svg{
                min-width: 20px;
                position: absolute;
                top: -44px;

                &:last-child{
                    right: 20px;
                }
            }
        }
    }
}