footer {
    background-color: $white;
    bottom: 0;
    box-shadow: 0px -5px 30px rgba(161, 167, 177, 0.12);
    padding: 28px 0 30px;
    
    body:not(.page-homepage) &{
        margin-top: 60px;
        position: fixed;
        width: 100%;
        z-index: 10;
    }

    .menuitems {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        position: relative;

        @include media-breakpoint-down(sm) {
            width: calc(100% - 80px);
            margin: 0 auto;
        }

        .slick-arrow {
            position: absolute;
            background-color: transparent;
            border: none;
            top: 50%;

            &:focus {
                outline: none;
            }

            svg {
                width: 20px;

                path {
                    fill: $white;
                }
            }

            &.prev-arrow {
                left: -10px;
                transform: translate(-100%, -50%);

                svg {
                    transform: rotate(180deg);
                }
            }

            &.next-arrow {
                right: -10px;
                transform: translate(100%, -50%);
            }
        }

        .slick-slide {
            margin: 0px 20px;

            *:focus {
                outline: none;
            }
        }

        .slick-slide {
            @include media-breakpoint-down(md) {
                margin: 0 10px;
            }
        }

        .menuitem {
            list-style: none;
            max-width: 48%;
            display: block;
            padding-right: 20px;
            position: relative;

            body.page-homepage &{
                &:first-child{
                    a{
                        span{
                            font-weight: 700;
                        }
                    }
                }
            }

            &:nth-last-of-type(1) {
                padding-right: 0px;
            }

            body:not(.page-homepage) & {
                &:first-child::before,
                &:last-child::after {
                    content: '';
                    display: inline-block;
                    padding: 5px;
                    position: absolute;
                    top: 6px;
                }
    
                &:first-child{
                    &::before{
                        border-bottom: 1.5px solid $grey-d2;
                        border-right: 1.5px solid $grey-d2;
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                        left: -25px;
                    }
                }
    
                &:last-child{
                    &::after{
                        border-right: 1.5px solid $grey-d2;
                        border-bottom: 1.5px solid $grey-d2;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        right: -25px;
                    }
    
                    span{
                        text-align: right;
                    }
                }
            }


            a {
                color: $blue;
                display: flex;
                font-size: 18px;
                font-weight: 500;
                flex-direction: column;
                letter-spacing: 1.5px;
                line-height: 21px;

                &:hover {
                    text-decoration: none;
                    color: $grey-d2;
                }

                .subtitle {
                    font-size: 13px;
                    line-height: 16px;
                    margin-top: 12;
                }
            }

            .navigation {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md){
    footer{
        overflow: hidden !important;

        // body:not(.page-homepage) &{
        //     position: relative;
        // }

        body.page-homepage & {
            .nav-items{
                padding-bottom: 20px;
                overflow: scroll;
                
                .menuitems{
                    margin: 0 16px;
                    
                    .menuitem{
                        max-width: unset;
                        
                        a{
                            span{
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}