.two-col-layout{
    margin-bottom: 120px;

    &.grey-bg{
        >.row{
            background-color: $grey-l2;
            padding: 106px 0;
            // margin: 0 32px;
        }
    }

    .main-title{
        color: $blue;
        font-size: calc(26px + (40 - 30) * ((100vw - 768px) / (1920 - 768)));
        font-weight: 500;
        line-height: calc(36px + (48 - 36) * ((100vw - 768px) / (1920 - 768)));
        letter-spacing: .25px;

        @media (min-width: 1920px){
            font-size: 40px;
            line-height: 48px;
        }

        @media (max-width: 768px){
            font-size: 30px;
            line-height: 36px;
        }
    }

    .paragraph-block{
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .image-block{
        margin-top: 0;

        .image-container{
            height: auto !important;
        }
    }

    .image-block, .embed-block{
        padding: 0 !important;
    }
}

@include media-breakpoint-down(lg){
    .two-col-layout{
        .main-title{
            margin-bottom: 20px;
        }       
    }
}

@include media-breakpoint-down(md){
    .two-col-layout{
        margin-bottom: 60px;

        &.grey-bg{
            padding: 0;

            >.row{
                background-color: $grey-l2;
                padding: 56px 0;
                margin: 0;
            }
        }     
    }
}