.two-col-block {
    .highlights-block {
        padding: 40px 0px;

        .content {
            padding: 16px;

            .img-container {
                padding: 0px;
            }

            .text {
                order: 1;
                padding: 16px 0px 0px;
            }
        }
    }
}

.highlights-block {
    max-width: 100vw !important;
    background-color: rgba($grey, .05);

    .content {
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        padding-top: 44px;
        padding-bottom: 44px;
        max-width: 1140px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding: 40px;
        }

        @include media-breakpoint-down(sm) {
            padding: 16px;
        }

        @include media-breakpoint-down(xs) {
            border-radius: 0px;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0px;

            @include media-breakpoint-down(md) {
                padding: 0px;
            }

            h2 {
                font-size: calc(20px + (28 - 20) * ((100vw - 300px) / (1440 - 300)));
                font-weight: 600;
                margin-bottom: 23px;
            }

            p {
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 1.5px;
            }
        }

        .img-container {
            display: flex;
            align-items: center;
            padding-right: 0px;

            &.embed{
                >div{
                    height: 100%;
                    width: 100%;

                    iframe{
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            video{
                height: 100%;
                max-height: 410px;
                max-width: 100%;
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                padding: 20px 0px 0px;
            }

            @include media-breakpoint-down(sm) {
                padding: 16px 0px 0px;
            }

            &.one-col {
                padding-left: 0;
                padding-right: 15px;
                margin-top: 40px;

                @include media-breakpoint-down(md) {
                    padding: 0;
                }

                img {
                    object-fit: cover;
                    max-height: 450px;
                }
            }

            img {
                width: 100%;
                border-radius: 4px;
            }
        }
    }
}