.paragraph-image-block {
    padding: 40px 0px;
    &.sticky-img {
        .image-container{
            img {
                position: sticky;
            }
        }
    }
    &.has-title {
        .image-container{
            img {
                margin-top: 8rem;
            }
        }
    }
    .image-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
        p {
            font-size: 20px;
            font-weight: 600;
            color: #5e5e5e;
            max-width: 200px;
            line-height: 24px;
            margin-bottom: 40px;
        }
        img {
            max-width: calc(100% - 30px);
            align-self: center;
            top: 0;
        }
    }
    h3{
        font-family: 'Neue Haas Unica Pro';
        font-size: 2rem;
        line-height: 2.8rem;
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
    }
}