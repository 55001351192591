.info-boxes-block{
    margin-bottom: 120px;
    margin-top: 60px;

    .main-title{
        color: $blue;
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: .25px;
        margin-bottom: 40px;

        @media (min-width: 1200px){
            padding-right: 57%;
        }
    }

    .box{
        background-color: $grey-l2;
        height: 100%;
        padding: 30px;

        .img{
            margin-right: 40px;

            img{
                min-width: 90px;
            }
        }
        
        .text{
            padding-top: 20px;

            p{
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.13px;
                line-height: 22px;
            }
        }
    }

    .break{
        flex-basis: 100%;
        width: 0;
    }
}

@include media-breakpoint-down(lg){
    .info-boxes-block{
        .box{
            flex-direction: column;

            .img{
                img{
                    min-width: 60px;
                }
            }
        }
    }
}