.editor-block {
    padding-top: 40px;
    padding-bottom: 40px;

    @include media-breakpoint-down(sm) {
        padding-right: 24px;
        padding-left: 24px;
    }
    .title {
        margin-bottom: 28px;
        padding: 0px;
        padding-left: 5px;
        @include media-breakpoint-down(lg) {
            padding-left: 10px;
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0px;
        }
        p {
            margin-bottom: 0px;
            font-size: 20px;
            line-height: 24px;
            &.name {
                font-weight: 600;
                line-height: 30px;
            }
        }
    }
    .content {
        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
            justify-content: center;
        }
        .image-container {
            padding-top: 10px;
            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
                display: none;
            }
            img {
                margin-bottom: 16px;
                width: 100%;
            }
        }
        .paragraph {
            margin-right: auto;

            p{
                a{
                    color: $blue;
                    display: block;
                    font-weight: 600;
                    padding-top: 12px;
                }
            }
        }
        .svg-container {
            margin-top: 40px;
            position: relative;
            opacity: 1;
            transition: .3s opacity ease-in-out;
            svg {
                position: absolute;
                z-index: 2;
                @include media-breakpoint-down(sm) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            &.hide {
                opacity: 0;
            }
        }
        .editor-body {
            opacity: 1;
            transform: translateY(0px);
            transition: .5s all .2s ease-in-out;
            &.hide {
                opacity: 0;
                transform: translateY(-20px);
            }
        }
        h3{
            font-family: 'Neue Haas Unica Pro';
            font-size: 2rem;
            line-height: 2.8rem;
            font-weight: bold;
            margin-bottom: 2.5rem;
        }
    }
}