.chart-block {
    padding-top: 40px;
    padding-bottom: 80px;

    h2 {
        margin-bottom: 40px;
    }

    .bar-chart-wrapper{
        ul {
            column-count: 2;
            flex-direction: column !important;
            flex-wrap: wrap;
            max-height: 120px;
            margin-top: 40px !important;

            li {
                margin-bottom: 8px;

                p {
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: .5px;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .chart-block {
        padding-top: 40px;
        padding-bottom: 80px;

        h2 {
            margin-bottom: 40px;
        }

        .bar-chart-wrapper {
            padding: 0 20px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .chart-block {
        .bar-chart-wrapper {
            padding: 0 80px;
        }
    }
}

@include media-breakpoint-down(md) {
    .chart-block {
        .bar-chart-wrapper {
            padding: 0 40px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .chart-block {
        .bar-chart-wrapper {
            padding: 0 20px;

            ul{
                max-height: unset;
            }
        }
    }
}